import React, { ReactNode, useEffect, useState } from "react";

export const Konami = ({ children }: { children?: ReactNode | undefined }) => {
  useKonamiCode(() => {
    document.querySelectorAll("source").forEach(source => {
      source?.parentElement?.removeChild(source);
    });
    document.querySelectorAll("img").forEach(img => {
      const { width, height } = img.getBoundingClientRect();
      const url = `https://loremflickr.com/${Math.round(width)}/${Math.round(height)}/cat?${Math.random()}`;
      img.src = url;
      img.srcset = url;
    });
  });
  return <>{children}</>;
};

const useKonamiCode = (handler: () => void) => {
  const [keys, setKeys] = useState<string[]>([]);
  const isKonamiCode =
    keys.join(" ") === "ArrowUp ArrowUp ArrowDown ArrowDown ArrowLeft ArrowRight ArrowLeft ArrowRight b a";
  useEffect(() => {
    let timeout: number;
    document.addEventListener("keydown", e => {
      setKeys(currentKeys => [...currentKeys, e.key]);
      clearTimeout(timeout);
      timeout = setTimeout(() => setKeys([]), 5000);
    });
  }, []);
  useEffect(() => {
    if (isKonamiCode) {
      handler();
      setKeys([]);
    }
  }, [isKonamiCode, handler]);
  return isKonamiCode;
};
